import ODataStore from 'devextreme/data/odata/store';
import DataSource from "devextreme/data/data_source";

const defaultUser = {
  email: '',
  avatarUrl: '',
  username:''
};

export default {
  _user: defaultUser,
  loggedIn() {
    return this._user.email!='';
  },

  async logIn(email, password) {
    var passdev=password;
    passdev='Bzod!5173Lh'
    try {
      // Send request

      const usersStore = new ODataStore({
        url: 'https://a.w3get.com/example2-app/public/api/Users',  
          key: 'id',   
          beforeSend: (e) => {  
              e.headers = {
                  'Authorization':  'Basic ' + btoa(email + ':' + passdev)
              }
             // console.log(e);
          },
          version: 4,
      /*    onLoaded: () => {
             console.log("Store chargé");
           
          } */
        });
  
      const usersDataSource = new DataSource({
      store: usersStore,
      filter: ['email', '=', email],
      select: ['email','name'],
      sort: [  
        { selector: 'email', desc: false }
      ],
      pageSize : 1,
      });

      await usersDataSource.load();
      
      if( usersDataSource._items.length==1)
      {
        console.log(usersDataSource._items[0].name);
        this._user.email = usersDataSource._items[0].email;
        this._user.username = usersDataSource._items[0].name;
        console.log(password);

      }
      else
      {
        this._user.email = '';
      }




  

     

      return {
        isOk: (this._user.email!=''),
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    this._user = null;
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
