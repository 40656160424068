<template>
  <div>
    <h2 class="content-block">Timeline</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        Put your content here
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
